import React from 'react'
import './StickyWhatsapp.css'
const StickyWhatsapp = () => {
  return (
    <>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
     <a href="https://api.whatsapp.com/send?phone=919951247896" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
    </a>
    </>
  )
}

export default StickyWhatsapp